import {Box, Typography, useMediaQuery} from "@mui/material";
import awardCardStyle from "../stylesheets/AwardCard.module.css"
import portfolioCardStyle from "../stylesheets/PortfolioCard.module.css";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";


const AwardCard = ({title, year, imgUrl}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const {t} = useTranslation();

    return (
        <Box className={awardCardStyle.mainContainer}>
            <div className={awardCardStyle.container}>

            </div>
            <div className={awardCardStyle.contentContainer}>
                <img src={imgUrl} className={awardCardStyle.image} alt={'bea icon'}/>
                {!isMobile &&
                    <Typography className={awardCardStyle.title} textAlign={'center'} minHeight={'80px'} mt={2}>
                        {t(title)}
                    </Typography>
                }
                {isMobile &&
                    <Typography className={awardCardStyle.title}>
                        Bea
                    </Typography>
                }
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop:isMobile ? '10px' : '20px'
                }}>
                    <img className={awardCardStyle.winnerImage} src={'/images/award-winner-img-left.png'} alt={'icon'}/>
                    <Box>
                        <Typography className={awardCardStyle.winner} variant={'h5'} fontWeight={'400'}
                                    fontFamily={'Proto Grotesk'}>
                            WINNER <br/>
                        </Typography>
                        <Typography className={awardCardStyle.winner} variant={'h4'} fontWeight={'400'}
                                    fontFamily={'Proto Grotesk'}>
                            {year}
                        </Typography>
                    </Box>
                    <img className={awardCardStyle.winnerImage} src={'/images/award-winner-img-right.png'} alt={'icon'}/>
                </Box>


            </div>
        </Box>
    )


}

export default AwardCard;