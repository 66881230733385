import {Box, Button, Typography, useMediaQuery} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import React, {useState} from "react";
import {useTheme} from "@mui/material/styles";
import {HashLink} from "react-router-hash-link";

const HomeVideo = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const {t} = useTranslation()

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Box sx={{
                position:'relative',
                width:'100vw',
                height:'100vh',
                overflow:'hidden',
                marginTop:'60px',
                display:'flex',
                flexDirection:'column',
                justifyContent:isMobile ? 'flex-start' : 'center',
                alignItems:isMobile ? 'center' : 'left',
                padding: isMobile ? 0 : '48px'
            }}>
                <Typography
                    variant={isMobile ? 'h3' : 'h2'}
                    textAlign={isMobile ? 'center' : 'left'}
                    width={isMobile ? '90%' : '50%'}
                    sx={{
                        position: 'relative',
                        zIndex:'500',
                        marginTop:isMobile ? '15%' : 0
                        //textShadow:'1px 1px 5px #000'
                    }}
                    fontFamily={'Poppins'}
                    fontWeight={'bold'}
                >
                    <Trans
                        i18nKey={`homeVideo.video`}
                        components={{ br: <br /> }}
                    />
                </Typography>
                <Typography
                    variant={isMobile ? 'h4' : 'h4'}
                    textAlign={isMobile ? 'center' : 'left'}
                    width={isMobile ? '90%' : '50%'}
                    sx={{
                        position: 'relative',
                        zIndex:'500',
                        marginTop:'1%'
                        //textShadow:'1px 1px 5px #000'
                    }}
                    fontFamily={'Poppins'}
                    fontWeight={'normal'}
                >
                    {t('homeVideo.video2')}
                </Typography>
                <Box sx={{
                    width:isMobile ? '100%' : '90%',
                    display:'flex',
                    marginTop:'3%',
                    justifyContent:isMobile ? 'center' : 'left',
                    paddingBottom:isMobile ? '0%' : '10%'
                }}>
                    <Button
                        key={"cards"}
                        component={HashLink}
                        smooth
                        to={'/#cards'}
                        variant={'contained'}
                        sx={{position:'relative',zIndex:'500'}}
                    >
                        <Typography pl={'10px'} pr={'10px'} variant={'h6'} fontFamily={'Poppins'} fontWeight={'400'}>{t('homeVideo.action')}</Typography>
                    </Button>
                </Box>
                {/* Immagine */}
                <img
                    src={isMobile ? "/images/gradients/gradient-mobile.png" : "/images/gradients/gradient-desktop.png"} // Sostituisci con il percorso della tua immagine
                    alt="Gradient overlay"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        zIndex: '400' // Sopra il video ma sotto i testi
                    }}
                />
                <video src={isMobile ? "/videos/video-main-mobile.webm" : "/videos/video-main-desktop.webm"}
                       title="YouTube video player"
                       style={{
                           position:'absolute',
                           top:'50%',
                           left:'50%',
                           transform: 'translate(-50%,-50%)',
                           width: '100%',
                           height: '100%',
                           objectFit: 'cover',
                           objectPosition:isMobile ? 'center':'right center'
                       }}
                       autoPlay loop muted playsInline
                       disablePictureInPicture>
                </video>
            </Box>
            {/*<iframe
                src="https://vimeo.com/166094859?background=1&autoplay=1&loop=1&muted=1"
                style={{
                    width: '100vw',
                    height: '100vh',
                    border: 'none',
                }}
                allow="autoplay; fullscreen"
            />*/}
            {/*<iframe src="https://www.youtube.com/embed/ScMzIvxBSi4?si=UnAgFqIcG7zdoQ2w"
                    title="YouTube video player"
                    style={{
                        width: '100vw',
                        height: '100vh',
                        border: 'none',
                    }}
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture;"
                    referrerPolicy="strict-origin-when-cross-origin"></iframe>*/}
        </Box>
    )
}

export default HomeVideo;