export const features = [
    {
        id: 9,
        title: "Sondaggi",
        asset:"SONDAGGI",
        translateKey:"features.sondaggio"
    },
    {
        id: 1,
        title: "Q&A",
        asset: "Q&A",
        translateKey:"features.qea"
    },
    {
        id: 2,
        title: "Click Battle",
        asset: "CLICKBATTLE",
        translateKey:"features.clickbattle"
    },
    {
        id: 3,
        title: "Slidephone",
        asset: "SLIDEPHONE",
        translateKey:"features.slidephone"
    },
    /*{
        id: 4,
        title: "Brainstorming",
    },*/
    {
        id: 5,
        title: "Traduzione",
        asset: "TRADUZIONE",
        translateKey:"features.translation"
    },
    {
        id: 8,
        title: "Reaction",
        asset:"REACTIONS",
        translateKey:"features.reaction"
    },

    {
        id: 10,
        title: "Quiz",
        asset: "QUIZ",
        translateKey:"features.quiz"
    },
    /*{
        id: 11,
        title: "Survey",
    },*/
    {
        id: 12,
        title:
            <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <img src={'/images/Star.png'} style={{width:'15px', marginRight:'10px'}} alt={'icon'}/>
                Wordcloud
            </div>,
        asset:"WORDCLOUD",
        translateKey:"features.wordcloud"
    },
    {
        id: 13,
        title:
            <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <img src={'/images/Star.png'} style={{width:'15px', marginRight:'10px'}} alt={'icon'}/>
                AI Summary
            </div>,
        asset:"AI_SUMMARY",
        translateKey:"features.aisummary"
    },
    /*{
        id: 14,
        title:
            <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <img src={'/images/Star.png'} style={{width:'15px', marginRight:'10px'}} alt={'icon'}/>
                Selfie wall
            </div>,
    },*/
];
